import Head from "next/head";
import React from "react";

import { META_TITLE_SUFFIX } from "@/constants/app";
import OpenJobs from "./OpenJobs";
import { NodeWithIsPreview, SmartEditableArea } from "@/util/SmartEditableArea";

export function OpenJobsPage(props: NodeWithIsPreview<Wif.Mgnl.OpenJobsPage>): JSX.Element | null {
  const { title, metaDescription, main, isPreview } = props;

  return (
    <>
      <Head>
        <title>{title + META_TITLE_SUFFIX}</title>
        <meta name="description" content={metaDescription} />
      </Head>

      <OpenJobs {...props} />

      <SmartEditableArea isPreview={isPreview} slotNode={main} />
    </>
  );
}
